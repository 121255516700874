<!-- 组织建设 -->
<template>
    <div class='oranizationBox'>
        <div class="header">
            <img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png"/>
            <img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
            <span class="header-txt">智惠妇儿</span>
            <!-- 关闭 刷新 -->
            <div class="idx-middlesx-box3">
                <div class="idx-middle-1">
                    <img class="fh" src="@/assets/image/zhfe/return.png" @click="goBack"/>
                    <img class="sx" src="@/assets/image/zhfe/icon/sx.png" alt="" @click="refresh" />
                </div>
            </div>
        </div>
        <!-- 时间 -->
        <div class="time-box">
            <Date></Date>
        </div>
        <div class="ob-content">
            <img src="@/assets/image/zhfe/organization.png" />
        </div>
    </div>
</template>

<script>
import Date from '@/views/commonComponents/date.vue';
export default {
    components: {
        Date
    },
    data() {
        return {
            gb: false,
        };
    },
    methods: {
        refresh() {
            this.$router.go(0); //刷新当前页面
        },
        goBack() {
            this.$router.go(-1);
        },
    },
    mounted() {
        if (`${this.readPass},${this.editPass}`.indexOf(this.$cookies.get("pass")) > -1 ) {
            this.gb = true;
        }else{
            this.gb = false;
        }
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>